import { Directive } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ISelectOption, ISelectOptions, SelectOptions } from '@app/shared/customize-comp/ora-select/model';

@Directive({
    selector: '[dirYear]',
    providers: [
        {
            provide: SelectOptions,
            useExisting: YearDirective,
        },
    ],
})
export class YearDirective implements ISelectOptions {
    options$ = of<ISelectOption[]>([]);
    startYear = 2021;
    currentYear: number = new Date().getFullYear();
    arrYears = Array<{ id: number; name: string }>();

    constructor() {
        this.arrYears = new Array();
        for (let index = this.currentYear + 1; index >= this.startYear; index--) {
            this.arrYears.push({
                id: index,
                name: '' + index,
            });
        }
        this.options$ = this.getDataSourceFromServer(this.arrYears);
    }

    getDataSourceFromServer(years): Observable<ISelectOption[]> {
        return of<ISelectOption[]>(
            years.map((item) => {
                const res: ISelectOption = {
                    value: item.id,
                    displayText: item.name,
                };
                return res;
            }),
        );
    }
}
