import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { AppConsts } from '@shared/AppConsts';
import { TYPE_VALIDATE } from '@shared/AppEnums';
import { LocalizationService } from 'abp-ng2-module';
import moment from 'moment';
// import * as moment from 'moment';



export interface commonResultValidDto {
  isError: boolean;
  messages: string;
}

@Component({
  selector: '<validation-custom>',
  template: `
    <span #mySpan class="form-control-feedback"
    class="{{isHidden ? '' : 'custom-error-validate'}} {{isInvalidData ? 'invalid-data-form' : ''}}"
    style="display: none;"
    [hidden]="isHidden">
        {{messages}}
    </span>`
})
export class ValidationCustomComponent implements OnChanges, AfterViewInit {
  localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

  constructor(private localization: LocalizationService) {}
  @ViewChild('mySpan') _mySpan: ElementRef;

  @Input() sModel: any;
  @Input() equalsModel: any;
  @Input() sMaxLength: number;
  @Input() sMinLength: number;
  @Input() sEqualLength: number;
  @Input() arrDataDuplicates: any;
  @Input() sValidRef: commonResultValidDto;
  @Input() sType: TYPE_VALIDATE;
  @Input() messagesInput: string;
  @Input() isNotValidateNullOrEmpty: boolean;
  @Input() nativeElement: any;
  @Input() bindingModel: any;
  @Input() maxValue: any;
  @Input() minValue: any;
  @Input() regexFormat: string;
  @Input() isFullHour: boolean;
  messages: string = '';
  isInvalidData: boolean = false;
  isHidden: boolean = false;
  isAfterViewInit: boolean = false;

  checkHidden(): void {
    this.messages = AppUtilityService.isNullOrWhiteSpace(this.messagesInput) ?
     this.localization.localize('_KhongDuocDeTrongTruongNay', this.localizationSourceName) :
      this.messagesInput;

    this.isHidden = true;
    this.isInvalidData = false;
    //if (AppUtilityService.isNullOrEmpty(this.sModel) && this.sModel !=0) {
    if (AppUtilityService.isNullOrWhiteSpace(this.sModel)) {
      this.isHidden = this.isNotValidateNullOrEmpty;
    } else if (!AppUtilityService.isNullOrWhiteSpace(this.sMaxLength) && this.sModel.length > this.sMaxLength) {
      this.messages = AppUtilityService.isNullOrWhiteSpace(this.messagesInput) ? ("Dữ liệu không thể vượt quá " + this.sMaxLength + " kí tự") : this.messagesInput;
      this.isHidden = false;
    } else if (!AppUtilityService.isNullOrWhiteSpace(this.sMinLength) && this.sModel.length < this.sMinLength) {
      this.messages = AppUtilityService.isNullOrWhiteSpace(this.messagesInput) ? ("Dữ liệu nhập tối thiểu " + this.sMinLength + " kí tự") : this.messagesInput;
      this.isHidden = false;
    } else if (!AppUtilityService.isNullOrWhiteSpace(this.sEqualLength) && this.sModel.length != this.sEqualLength) {
      this.messages = AppUtilityService.isNullOrWhiteSpace(this.messagesInput) ? ("Dữ liệu nhập phải = " + this.sEqualLength + " kí tự") : this.messagesInput;
      this.isHidden = false;
    } else if (this.sType == TYPE_VALIDATE.Number && !AppUtilityService.validateNumber(this.sModel)) {
      this.messages = "Kí tự không đúng định dạng";
      this.isHidden = false;
    } else if (this.sType == TYPE_VALIDATE.Email && !AppUtilityService.validateEmail(this.sModel)) {
      this.messages = "Email không đúng định dạng";
      this.isHidden = false;
    } else if (this.sType == TYPE_VALIDATE.Moment && !AppUtilityService.validateMoment(this.sModel)) {
      this.messages = "Nhập ngày/tháng/năm";
      this.isHidden = false;
    }
    // else if (this.sType == TYPE_VALIDATE.Datetime && !AppUtilityService.validateDatetime(this.sModel)) {
    //   this.messages = "Nhập ngày/tháng/năm";
    //   this.isHidden = false;
    // }
    else if (this.sType == TYPE_VALIDATE.Datetime && this.maxValue && AppUtilityService.compareDatetime(this.sModel, this.maxValue, this.isFullHour) > 0) {
      let maxDate = new Date(this.maxValue);
      this.messages = "Ngày nhập phải nhỏ hơn hoặc bằng " + (this.isFullHour ? moment(maxDate).format('DD/MM/YYYY HH:mm') : (maxDate.getDate() + "/" + (maxDate.getMonth() + 1) + "/" + maxDate.getFullYear()));
      this.isHidden = false;
    }
    else if (this.sType == TYPE_VALIDATE.Datetime && this.minValue && AppUtilityService.compareDatetime(this.sModel, this.minValue, this.isFullHour) < 0) {
      let minDate = new Date(this.minValue);
      this.messages = "Ngày nhập phải lớn hơn hoặc bằng " + (this.isFullHour ? moment(minDate).format('DD/MM/YYYY HH:mm') : (minDate.getDate() + "/" + (minDate.getMonth() + 1) + "/" + minDate.getFullYear()));
      this.isHidden = false;
    }
    else if (this.sType == TYPE_VALIDATE.MinValue && this.sModel != null && this.equalsModel != null && parseInt(this.sModel) < parseInt(this.equalsModel)) {
      this.messages = "Giá trị nhập < giá trị tối thiểu là: (" + this.equalsModel + ")";
      this.isHidden = false;
    } else if (this.sType == TYPE_VALIDATE.MaxValue && this.sModel != null && this.equalsModel != null && parseInt(this.sModel) > parseInt(this.equalsModel)) {
      this.messages = "Giá trị nhập > giá trị cho phép là: (" + this.equalsModel + ")";
      this.isHidden = false;
    } else if (this.sType == TYPE_VALIDATE.PassWord && this.sModel != null && AppUtilityService.validatePassword(this.sModel) === false) {
      this.messages = 'Mật khẩu tối thiểu 8 ký tự, bao gồm chữ và số!';
      this.isHidden = false;
    } else if (this.sType == TYPE_VALIDATE.Phone && this.sModel != null && AppUtilityService.validateNumberPhone(this.sModel) === false) {
      this.messages = "Số điện thoại không đúng định dạng";
      this.isHidden = false;
    } else if (this.sType == TYPE_VALIDATE.RegexFormat && this.sModel != null && AppUtilityService.validateRegex(this.sModel, new RegExp(this.regexFormat)) === false) {
      this.messages = AppUtilityService.isNullOrWhiteSpace(this.messagesInput) ? "Giá trị không đúng định dạng" : this.messagesInput;
      this.isHidden = false;
    }
    else if (this.sType == TYPE_VALIDATE.CheckDuplicates && this.arrDataDuplicates != null && this.arrDataDuplicates.includes(this.sModel)) {
      this.messages = this.messagesInput || 'Giá trị nhập đã tồn tại!';
      this.isInvalidData = true;
      this.isHidden = false;
    } else if (this.sType == TYPE_VALIDATE.Dynamic) {
      this.messages = this.messagesInput || 'Giá trị nhập không hợp lệ !';
      this.isInvalidData = true;
      this.isHidden = false;
    }
    else if (this.sType == TYPE_VALIDATE.ValidRef && !AppUtilityService.isNullOrWhiteSpace(this.sModel) && this.sValidRef != null && this.sValidRef.isError) {
      this.messages = this.sValidRef.messages || "Giá trị không hợp lệ !";
      this.isHidden = false;
    }
    else if (this.sType == TYPE_VALIDATE.Array && !AppUtilityService.isNullOrWhiteSpace(this.sModel) && this.sModel.length == 0) {
      this.messages = this.messagesInput || "Không được để trống trường này ";
      this.isHidden = false;
    } else if(this.sType == TYPE_VALIDATE.CCCD && AppUtilityService.validateCCCD(this.sModel) === false) {
      this.messages = this.messagesInput || "Mã không hợp lệ";
      this.isHidden = false;
    }

    if (this.isAfterViewInit && this.nativeElement && this.nativeElement.style) {
      this.nativeElement.style.cssText = this.isHidden ?
        null :
        "color: #fd397a; border: 1px solid #fd397a;";
    }
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.isAfterViewInit) {
      if(_changes.sModel != null &&_changes.sModel.currentValue){
        this._mySpan.nativeElement.style.display = 'inline';
      }

    }

    this.checkHidden();

  }

  ngAfterViewInit() {
    this.isAfterViewInit = true;
    if (this.bindingModel) {
      this._mySpan.nativeElement.style.display = 'inline';
      this.checkHidden();
    }
  }
}
