import { Injectable } from '@angular/core';
import { PhongXetNghiemDto } from '@shared/service-proxies/service-proxies';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LoadModuleService } from '../../../../shared/services/load-module.service';
import { PhongXetNghiemCreateEditComponent } from '../thong-tin/phong-xet-nghiem-create-edit.component';

@Injectable({ providedIn: 'root' })
export class PhongXetNghiemUXService {
    constructor(private modalService: NzModalService,
                private loadModule: LoadModuleService) {
    }

    public async openCreateOrEditModal(option: { dataItem: PhongXetNghiemDto; callBack?: any }): Promise<void> {

        await this.loadModule.loadModuleDanhGiaPXN();

        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: PhongXetNghiemCreateEditComponent,
            nzComponentParams: {
                dataItem: Object.assign(new PhongXetNghiemDto(), { ...option.dataItem }),
            },
            nzMaskClosable: false,
            nzWidth: '50%',
        });

        modal.afterClose.subscribe((result: PhongXetNghiemDto) => {
            option.callBack(result);
        });
    }
}
