<nz-select nzShowSearch
           nzServerSearch
           nzAllowClear={{AllowClear}}
           nzPlaceHolder="{{placeHolder}}"
           nzMode="default"
           [(ngModel)]="_value"
           [nzDisabled]='_isDisabled'
           (nzFocus)="onFocus"
           style="width:100%"
           [formControl]="control"
           (ngModelChange)="onChangeValue($event)"
           (nzOnSearch)="search($event)">
    <nz-option *ngFor="let item of optionList"
               [nzLabel]="item.name"
               [nzValue]="item.id">
    </nz-option>
</nz-select>
