import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, Pipe, PipeTransform } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalHelper } from '@delon/theme';

import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable, Observer } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ViewPdfKetQuaComponent } from './modal/view-pdf/view-pdf.component';

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

@Component({
  selector: 'custom-file-multi-upload-controler-v2',
  template: `
<div style="display: inline">
  
  <span style="display: inline">
    <!-- Phần tải tệp -->
    <nz-upload
      [nzAccept]="acceptType"
      [nzMultiple]="true" 
      [nzBeforeUpload]="beforeUpload"
      (nzChange)="uploadTepDinhKem($event)"
      *ngIf="!isView"
      [nzAction]="uploadUrl"
      [nzShowUploadList]="false"
      style="display: flex">
      <button nz-button><i nz-icon nzType="upload"></i><span>Tải tệp</span></button>
    </nz-upload>
  </span>

  <span *ngIf="isViewText" style="margin-right: 10px; overflow-wrap: break-word;">
    <span *ngFor="let file of sModel; let i = index">
      <div style="margin-left: 2px">
       <span style="margin-right: 10px"><i>{{ file | extractAfterSlash }}</i></span>
       <a *ngIf="false" nz-button nzSize="small" nzType="link" (click)="viewTepDinhKem(sModel[i])">
         <i nz-icon nzType="eye" nzTheme="outline"></i>
       </a>
       <a nz-button nzSize="small" nzType="link" (click)="taiTepDinhKem(sModel[i])">
         <i nz-icon nzType="download" nzTheme="outline"></i>
       </a>
       <a *ngIf="!isView && !disableDelete" nz-button nzSize="small" nzType="link" (click)="xoaDuongDanTepDinhKem(i)">
         <i nz-icon nzType="close" nzTheme="outline"></i>
       </a>
      </div>
    </span>
  </span>
  
</div>

  `,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomFileMultiUploadV2ControlComponent),
    multi: true
  }]
})

export class CustomFileMultiUploadV2ControlComponent implements AfterViewInit {
    @Input() isView = false;
    @Input() isViewText = true;
    @Input() isViewTepDinhKem = false;
    @Input() sLimit? = 15;
    @Input() urlApi = '';
    @Input() sModel: string[] = []; 
    @Input() sFileName: string[] = []; 
    @Input() sType: string[] = [];
    @Input() uploadUrl: string = '';
    @Input() viewUrl: string = '';
    @Input() downloadUrl: string = '';
    @Input() acceptType: string = '.pdf,.docx,.doc,.xlsx,.xls,.txt,.pptx';
    @Input() disableDelete: boolean = false;
    @Output() sModelChange = new EventEmitter<string[]>();
    @Output() sFileNameChange = new EventEmitter<string[]>();
    @Output() sTypeChange = new EventEmitter<string[]>();
    @Output() eventChange = new EventEmitter();
  
    constructor(private modalHelper: ModalHelper) { }

    ngAfterViewInit() {}
  
    beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
      new Observable((observer: Observer<boolean>) => {
        var indexOfPoint = file.name.lastIndexOf(".");
        var fileType = file.name.substring(indexOfPoint, file.name.length);
        const acceptTypeCheck = this.acceptType.includes(fileType);
        if (!acceptTypeCheck) {
          abp.notify.error("File upload phải đúng định dạng " + this.acceptType)
          observer.complete();
          return;
        }
        const mb = file.size! / 1024 / 1024;
        const isCheckSize = mb < this.sLimit;
        if (!isCheckSize) {
          abp.notify.error("File upload không được quá " + this.sLimit + "MB")
          observer.complete();
          return;
        }
        observer.next(acceptTypeCheck && isCheckSize);
        observer.complete();
      });
  
    uploadTepDinhKem(info: any): void {
      if (info.file.status == 'error') {
        info.file.message = info.file.error.error.message;
      }
  
      if (info.file.response && info.file.response.success == true) {
        var result = info.file.response.result;
  
        // Thêm thông tin tệp vào mảng
        this.sFileName.push(result.lstDataResult[0].fileName);
        this.sModel.push(result.lstDataResult[0].path);
        this.sType.push(result.lstDataResult[0].type);
        
        this.sModelChange.emit(this.sModel);
        this.sTypeChange.emit(this.sType);
        this.sFileNameChange.emit(this.sFileName);
      }
    }
  
    viewTepDinhKem(url) {
      let path = this.urlApi + `/api/file/GoToViewTepDinhKem?url=` + url;
      if (this.viewUrl != '') {
        path = this.viewUrl + "?url=" + url;
      }
      this.modalHelper
        .create(
          ViewPdfKetQuaComponent,
          {
            path: path,
          },
          {
            size: 1300,
            includeTabs: false,
            modalOptions: {
              nzTitle: 'Xem tài liệu',
            },
          },
        )
        .subscribe((result) => {
          if (result) {
          }
        });
    }
  
    taiTepDinhKem(url) {
      let path = this.urlApi + `/api/file/DownloadFile?url=` + url;
      if (this.downloadUrl != '') {
        path = this.downloadUrl + "?url=" + url;
      }
      location.href = path;
    }
  
    xoaDuongDanTepDinhKem(index: number) {
      this.sModel.splice(index, 1);
      this.sFileName.splice(index, 1);
      this.sType.splice(index, 1);
      this.sModelChange.emit(this.sModel);
    }
}

@Pipe({
  name: 'extractAfterSlash'
})
export class ExtractAfterSlashPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (!value) return '';
    const parts = value.split('/');
    return parts.length > 1 ? parts[parts.length - 1] : value;
  }

}
  