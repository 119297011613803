import {
    Component,
    Input,
    OnInit,
    OnChanges,
    AfterViewInit,
    Output,
    EventEmitter,
    SimpleChanges,
    Injector
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { HAI_DepartmentServiceProxy, HAI_DepartmentPagingListRequest, PHAM_VI_DU_LIEU_HAI } from '@shared/service-proxies/service-proxies';
import { LocalizationService } from 'abp-ng2-module';

@Component({
    selector: 'hai-department-tree',
    templateUrl: './hai-department-tree.component.html',
    styleUrls: ['./hai-department-tree.component.scss']
})
export class HaiDepartmentTreeControlComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() organisationId: number;
    @Input() scope: number = PHAM_VI_DU_LIEU_HAI.QUOC_GIA;

    @Input() totalDepartment: number = 0;
    @Output() totalDepartmentChange = new EventEmitter<number>();

    @Input() arrOrganisationId: number[] = [];
    @Output() arrOrganisationIdChange = new EventEmitter<number[]>();

    @Input() arrDepartmentId: number[] = [];
    @Output() arrDepartmentIdChange = new EventEmitter<number[]>();

    @Output() eventChange = new EventEmitter<void>();

    departmentTypes: any[] = [];
    departmentIds: string[] = [];

    isLoadInit: boolean = false;

    localization: LocalizationService;

    constructor(
        injector: Injector,
        private dataServices: HAI_DepartmentServiceProxy
    ) {
        this.localization = injector.get(LocalizationService);
    }

    ngAfterViewInit(): void {
        this.loadDepartmentTypes();
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.organisationId || changes.scope) && this.isLoadInit) {
            this.loadDepartmentTypes();
        }
    }

    onChange($event: any): void {

        const allOrganisationKeys = this.departmentIds.filter((item: string) => item.startsWith('coso_'));
        const allDepartmentKeys = this.departmentIds.filter((item: string) => item.startsWith('dept_'));

        const selectedDepartments = new Set<string>(allDepartmentKeys);

        // Thêm tất cả khoa/phòng con của tổ chức cha nếu tổ chức cha được chọn
        allOrganisationKeys.forEach((organisationKey: string) => {
            const organisationId = organisationKey.replace('coso_', '');
            const organisationNode = this.departmentTypes.find(item => item.value === Number(organisationId));

            if (organisationNode && organisationNode.children) {
                organisationNode.children.forEach(child => {
                    selectedDepartments.add(child.key); // Thêm tất cả các khoa/phòng con
                });
            }
        });

        // arrOrganisationId
        this.arrOrganisationId = allOrganisationKeys.map(key => parseInt(key.replace(/\D/g, ''), 10));
        this.arrOrganisationIdChange.emit(this.arrOrganisationId);

        // arrDepartmentId
        this.arrDepartmentId = Array.from(selectedDepartments).map(key =>
            parseInt(key.replace(/\D/g, ''), 10)
        );
        this.arrDepartmentIdChange.emit(this.arrDepartmentId);

        // Cập nhật lại departmentIds
        this.departmentIds = Array.from(new Set([...selectedDepartments]));

        if (this.eventChange) {
            this.eventChange.emit();
        }
    }

    displaySelectedTitle = (selectedKeys: any): string => {
        if (!selectedKeys) {
            return '';
        }

        const keys = Array.isArray(selectedKeys) ? selectedKeys : [selectedKeys];
        const titles: string[] = [];

        keys.forEach((key: any) => {
            const keyValue = typeof key === 'string' ? key : key.key || key.value;

            if (keyValue.startsWith('coso_')) {
                const organisationId = keyValue.replace('coso_', '');
                const organisationNode = this.departmentTypes.find(item => item.value === Number(organisationId));

                if (organisationNode && organisationNode.children) {
                    organisationNode.children.forEach(child => titles.push(child.title));
                }
            } else if (keyValue.startsWith('dept_')) {
                const deptId = keyValue.replace('dept_', '');
                this.departmentTypes.forEach(item => {
                    const child = item.children?.find(child => child.value === Number(deptId));
                    if (child) {
                        titles.push(child.title);
                    }
                });
            }
        });

        return titles.join(', ');
    };


    loadDepartmentTypes(): void {
        this.departmentTypes = [];
        this.departmentIds = [];

        const input = new HAI_DepartmentPagingListRequest();
        input.organisationId = this.organisationId || 0;
        input.languageCode = this.localization.currentLanguage?.name;
        input.scopeOfDepartment = this.scope;

        abp.ui.setBusy();
        this.dataServices
            .getListDepartmentAndGroup(input)
            .pipe(finalize(() => abp.ui.clearBusy()))
            .subscribe(val => {
                this.isLoadInit = true;

                if (val.dataResult) {
                    this.departmentTypes = val.dataResult.map(item => ({
                        title: item.organisationName,
                        value: item.organisationId,
                        key: `coso_${item.organisationId}`,
                        children: item.items
                            ? item.items.map(it => ({
                                title: it.name,
                                value: it.id,
                                key: `dept_${it.id}`,
                                isLeaf: true
                            }))
                            : []
                    }));

                    // totalDepartment
                    this.totalDepartment = 0;
                    val.dataResult.forEach(item => {
                        this.totalDepartment += (item.items?.length||0);
                    });
                    this.totalDepartmentChange.emit(this.totalDepartment);

                } else {
                    this.arrOrganisationId = [];
                    this.arrDepartmentId = [];
                    this.arrOrganisationIdChange.emit([]);
                    this.arrDepartmentIdChange.emit([]);

                    // totalDepartment
                    this.totalDepartment = 0;
                    this.totalDepartmentChange.emit(this.totalDepartment);
                }
            });
    }



}
