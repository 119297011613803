<ora-page-header style='position: sticky;top: 0;'
                 [title]="dataItem.id > 0 ? 'Thông tin khoa/phòng xét nghiệm' : 'Thêm mới thông tin khoa/phòng xét nghiệm'"
                 [action]='actionHeader'>
    <ng-template #actionHeader>
        <div>
            <ord-cancel-btn *ngIf='!(this.appSession.user.taiKhoanPhongXetNghiemId> 0)'
                            (click)='close.emit()'></ord-cancel-btn>
            <ord-save-btn (click)='save()' text='Lưu'></ord-save-btn>
        </div>
    </ng-template>
</ora-page-header>
<nz-card class='ora-custom' [id]='formId'>
    <nz-tabset>
        <nz-tab [nzForceRender]='true' nzTitle='Thông tin chung'>
            <div nz-row [nzGutter]='8'>
                <div nz-col class='gutter-row' nzXs='24' nzSm='24' nzMd='12' nzLg='12' nzXl='12'>
                    <label>Cơ sở KCB<span class='text-red'>*</span></label>
                    <co-so-combo [disabled]='this.appSession.user.coSoId> 0 ? true: false'
                                 [(ngModel)]='dataItem.coSoId'></co-so-combo>
                    <validation-custom [sModel]='dataItem.coSoId'></validation-custom>
                </div>
                <div nz-col class='gutter-row' nzXs='24' nzSm='24' nzMd='12' nzLg='12' nzXl='12'>
                    <label class='font-weight-normal'>Mã khoa/phòng xét nghiệm</label>
                    <input nz-input [(ngModel)]='dataItem.maPhong'>
                </div>
            </div>
            <div nz-row [nzGutter]='8'>
                <div nz-col class='gutter-row ord-form-control' nzXs='24' nzSm='24' nzMd='12' nzLg='12' nzXl='12'>
                    <label class='font-weight-normal'>Tên khoa/phòng xét nghiệm<span class='text-red'>*</span></label>
                    <input nz-input [(ngModel)]='dataItem.tenPhong'>
                    <validation-custom [sModel]='dataItem.tenPhong'></validation-custom>
                </div>
                <div nz-col class='gutter-row ord-form-control' nzXs='24' nzSm='24' nzMd='12' nzLg='12' nzXl='12'>
                    <label class='font-weight-normal'>Trưởng (phụ trách) PXN<span class='text-red'>*</span></label>
                    <input nz-input [(ngModel)]='dataItem.hoTenNPT'>
                    <validation-custom [sModel]='dataItem.hoTenNPT'></validation-custom>
                </div>
            </div>
            <div nz-row [nzGutter]='8'>
                <div nz-col class='gutter-row ord-form-control' nzXs='24' nzSm='24' nzMd='12' nzLg='12' nzXl='12'>
                    <label class='font-weight-normal'>Điện thoại người phụ trách<span class='text-red'>*</span></label>
                    <input nz-input [(ngModel)]='dataItem.dienThoaiNPT'>
                    <validation-custom [sModel]='dataItem.dienThoaiNPT'
                                       [sType]='TYPE_VALIDATE.Phone'></validation-custom>
                </div>
                <div nz-col class='gutter-row ord-form-control' nzXs='24' nzSm='24' nzMd='12' nzLg='12' nzXl='12'>
                    <label class='font-weight-normal'>Email người phụ trách<span class='text-red'>*</span></label>
                    <input nz-input [(ngModel)]='dataItem.emailNPT'>
                    <validation-custom [sModel]='dataItem.emailNPT' [sType]='TYPE_VALIDATE.Email'></validation-custom>
                </div>
            </div>
            <div nz-row [nzGutter]='8'>
                <div nz-col class='gutter-row ord-form-control' nzXs='24' nzSm='24' nzMd='12' nzLg='12' nzXl='12'>
                    <label class='font-weight-normal'>Cấp độ phòng xét nghiệm<span class='text-red'>*</span></label>
                    <enum-combo [EnumCode]='ComboBoxEnumCode.CAP_DO_PHONG_XET_NGHIEM'
                                [(ngModel)]='dataItem.capDo'>
                    </enum-combo>
                    <validation-custom [sModel]='dataItem.capDo'></validation-custom>
                </div>
                <div nz-col class='gutter-row ord-form-control' nzXs='24' nzSm='24' nzMd='12' nzLg='12' nzXl='12'>
                    <label class='font-weight-normal'>Tính chất phòng xét nghiệm<span class='text-red'>*</span></label>
                    <enum-combo [EnumCode]='ComboBoxEnumCode.TINH_CHAT_PHONG_XET_NGHIEM'
                                [(ngModel)]='dataItem.tinhChat'>
                    </enum-combo>
                    <validation-custom [sModel]='dataItem.tinhChat'></validation-custom>
                </div>
            </div>
            <div nz-row [nzGutter]='8'>
                <div nz-col class='gutter-row ord-form-control' nzXs='24' nzSm='24' nzMd='12' nzLg='12' nzXl='12'>
                    <label class='font-weight-normal'>Hoạt động</label>
                    <div>
                        <nz-switch [nzDisabled]='isView' [(ngModel)]='dataItem.hoatDong'></nz-switch>
                    </div>
                </div>
            </div>
        </nz-tab>
        <nz-tab [nzForceRender]='true' nzTitle='Danh mục kỹ thuật xét nghiệm đang thực hiện'>
            <div class='text-right margin-bottom-10'>
                <button *ngIf='!isView' nz-button nz-tooltip='Thêm mới' nzType='default' (click)='addKyThuat()'>
                    <i nz-icon nzType='plus-circle'></i>Thêm mới
                </button>
            </div>
            <nz-table #kyThuatTable [nzData]='dataItem.listKyThuat' [nzShowPagination]='false'
                      [nzFrontPagination]='false'>
                <thead>
                <tr>
                    <th width='50px' nzAlign='center'>STT</th>
                    <th nzAlign='center' width='300px'>Danh mục KTXN đang thực hiện</th>
                    <th nzAlign='center' width='200px'>KTXN có thực hiện nội kiểm</th>
                    <th nzAlign='center' width='200px'>KTXN có thực hiện ngoại kiểm hay so sánh liên phòng</th>
                    <th nzAlign='center' width='200px'>Số lượng xét nghiệm trung bình/năm</th>
                    <th *ngIf='!isView' nzAlign='center' width='80px'>Thao tác</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor='let kyThuat of kyThuatTable.data; index as i'>
                    <tr>
                        <td nzAlign='center'>{{i + 1}}</td>
                        <td>
                            {{kyThuat.tenKyThuat}}
                        </td>
                        <td nzAlign='center'>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <nz-switch [nzDisabled]='isView' [(ngModel)]='kyThuat.thucHienNoiKiem'></nz-switch>
                            </nz-form-control>
                        </td>
                        <td nzAlign='center'>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <nz-switch [nzDisabled]='isView'
                                           [(ngModel)]='kyThuat.thucHienNgoaiKiemLienPhong'></nz-switch>
                            </nz-form-control>
                        </td>
                        <td>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <nz-input-number [nzDisabled]='isView' [(ngModel)]='kyThuat.soXetNghiemTrungBinh'
                                                 style='width: 100%'>
                                </nz-input-number>
                            </nz-form-control>
                        </td>
                        <td *ngIf='!isView' nzAlign='center'>
                            <a nz-popconfirm
                               class='badge-danger-color'
                               class='text-danger'
                               [nzPopconfirmTitle]="'Bạn có chắc chắn muốn xóa?'"
                               title='Xóa kỹ thuật đánh giá'
                               (nzOnConfirm)='deleteKyThuat(i)'
                               nzOkText='Đồng ý'
                               nzCancelText='Hủy'
                               nzPopconfirmPlacement='topRight' nzType='link'>
                                <i nz-icon style='color:#E14D2A' nzType='delete'></i>
                            </a>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </nz-table>
        </nz-tab>
        <nz-tab [nzForceRender]='true' nzTitle='Nhân lực phòng xét nghiệm'>
            <!--            <div class='text-right margin-bottom-10'>-->
            <!--                <button *ngIf='!isView' nz-button nz-tooltip='Thêm mới' nzType='default' (click)='addNhanSu()'>-->
            <!--                    <i nz-icon nzType='plus-circle'></i>Thêm mới-->
            <!--                </button>-->
            <!--            </div>-->
            <nz-table #nhanSuTable [nzData]='dataItem.listNhanSu' [nzShowPagination]='false'
                      [nzFrontPagination]='false'>
                <thead>
                <tr>
                    <th width='50px' nzAlign='center'>STT</th>
                    <th nzAlign='center' width='300px'>Trình độ</th>
                    <th nzAlign='center' width='150px'>Số lượng</th>
                    <!--                    <th *ngIf='!isView' nzAlign='center' width='80px'>Thao tác</th>-->
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor='let nhanSu of nhanSuTable.data; index as i'>
                    <tr>
                        <td nzAlign='center'>{{i + 1}}</td>
                        <td>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <input nz-input [readOnly]='nhanSu.soThuTu===99 ? false : true'
                                       [(ngModel)]='nhanSu.trinhDo' />
                            </nz-form-control>
                        </td>
                        <td nzAlign='center'>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <nz-input-number [nzDisabled]='isView || nhanSu.soThuTu=== 3 || nhanSu.soThuTu===100'
                                                 (ngModelChange)='changeSoLuong(nhanSu, $event)'
                                                 [(ngModel)]='nhanSu.soLuong'
                                                 style='width: 100%'>
                                </nz-input-number>
                            </nz-form-control>
                        </td>
                        <!--                        <td *ngIf='!isView' nzAlign='center'>-->
                        <!--                            <a nz-popconfirm-->
                        <!--                               class='badge-danger-color'-->
                        <!--                               class='text-danger'-->
                        <!--                               [nzPopconfirmTitle]="'Bạn có chắc chắn muốn xóa?'"-->
                        <!--                               title='Xóa nhân sự đánh giá'-->
                        <!--                               (nzOnConfirm)='deleteNhanSu(i)'-->
                        <!--                               nzOkText='Đồng ý'-->
                        <!--                               nzCancelText='Hủy'-->
                        <!--                               nzPopconfirmPlacement='topRight' nzType='link'>-->
                        <!--                                <i nz-icon style='color:#E14D2A' nzType='delete'></i>-->
                        <!--                            </a>-->
                        <!--                        </td>-->
                    </tr>
                </ng-container>
                </tbody>
            </nz-table>
        </nz-tab>

        <nz-tab [nzForceRender]='true' nzTitle='Danh mục trang thiết bị của phòng xét nghiệm'>
            <div class='text-right margin-bottom-10'>
                <button *ngIf='!isView' nz-button nz-tooltip='Thêm mới' nzType='default' (click)='addThietBi()'>
                    <i nz-icon nzType='plus-circle'></i>Thêm mới
                </button>
            </div>
            <nz-table #thietBiTable [nzData]='dataItem.listThietBi' [nzShowPagination]='false'
                      [nzFrontPagination]='false'>
                <thead>
                <tr>
                    <th nzAlign='center' width='50px'>STT</th>
                    <th width='150px'>Tên thiết bị</th>
                    <th width='100px'>Mã số</th>
                    <th width='100px'>Quốc gia sản xuất</th>
                    <th width='100px'>Đơn vị tính</th>
                    <th width='100px'>Hãng sản xuất</th>
                    <th width='100px'>Model</th>
                    <th width='100px'>Serial</th>
                    <th width='100px'>Năm sử dụng</th>
                    <th width='100px'>Ghi chú</th>
                    <th *ngIf='!isView' width='100px'>Thao tác</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor='let thietBi of thietBiTable.data; index as i'>
                    <tr>
                        <td nzAlign='center'>{{i + 1}}</td>
                        <td>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <input nz-input [(ngModel)]='thietBi.tenThietBi' />
                            </nz-form-control>
                        </td>
                        <td nzAlign='center'>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <input nz-input [(ngModel)]='thietBi.maSo' />
                            </nz-form-control>
                        </td>
                        <td nzAlign='center'>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <input nz-input [(ngModel)]='thietBi.tenQuocGia' />
                            </nz-form-control>
                        </td>
                        <td nzAlign='center'>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <input nz-input [(ngModel)]='thietBi.donViTinh' />
                            </nz-form-control>
                        </td>
                        <td nzAlign='center'>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <input nz-input [(ngModel)]='thietBi.hangSanXuat' />
                            </nz-form-control>
                        </td>
                        <td nzAlign='center'>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <input nz-input [(ngModel)]='thietBi.model' />
                            </nz-form-control>
                        </td>
                        <td nzAlign='center'>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <input nz-input [(ngModel)]='thietBi.serial' />
                            </nz-form-control>
                        </td>
                        <td>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <nz-input-number [nzDisabled]='isView' [(ngModel)]='thietBi.namSuDung'
                                                 style='width: 100%'>
                                </nz-input-number>
                            </nz-form-control>
                        </td>
                        <td nzAlign='center'>
                            <nz-form-control [nzSm]='24' nzErrorTip='Giá trị không được để trống!'>
                                <input nz-input [(ngModel)]='thietBi.ghiChu' />
                            </nz-form-control>
                        </td>
                        <td *ngIf='!isView' nzAlign='center'>
                            <a nz-popconfirm
                               class='badge-danger-color'
                               class='text-danger'
                               [nzPopconfirmTitle]="'Bạn có chắc chắn muốn xóa?'"
                               title='Xóa thiết bị đánh giá'
                               (nzOnConfirm)='deleteThietBi(i)'
                               nzOkText='Đồng ý'
                               nzCancelText='Hủy'
                               nzPopconfirmPlacement='topRight' nzType='link'>
                                <i nz-icon style='color:#E14D2A' nzType='delete'></i>
                            </a>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </nz-table>
        </nz-tab>
    </nz-tabset>
</nz-card>
