import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import {NgSelectModule} from '@ng-select/ng-select';
import { CustomizeCompModule } from '@app/shared/customize-comp/customize-comp.module';
import { DataCompService } from '@app/shared/data-comp/data-comp.service';
import { SHARED_ZORRO_MODULES } from '@shared/shared-zorro.module';
import { ReadFileExcelService } from '../customize-comp/services/read-file-excel.service';
import { DonViHanhChinhComboComponent } from './combo-filter/don-vi-hanh-chinh/don-vi-hanh-chinh-combo.component';
import { StatusActiveControlComponent } from './control-comp/status-active-control.component';
import { TreeViewSelectTypeComponent } from './control-comp/tree-view-select-type/tree-view-select-type.component';
import { CustomSourceDirective } from './directive/customSource.directive';
import { EnumComboComponent } from './_base/enum-combo.component';
import { CoSoComboComponent } from './combo-filter/co-so/co-so-combo.component';
import { RoleComboComponent } from './combo-filter/role/role-combo.component'
import { EmptyComponent } from './control-comp/_empty/empty.component';
import { ICD9ComboComponent } from './combo-filter/icd9/icd9-combo.component';
import { DropListDownComboComponent } from './control-comp/chi-so/droplist-down/droplist-down-combo.component';
import { CheckboxListComponent } from './control-comp/chi-so/checkbox-list/checkbox-list.component';
import { RadioListComponent } from './control-comp/chi-so/radio-list/radio-list.component';
import { DynamicControlComponent } from './control-comp/dynamic-control/dynamic-control.component';
import { DictionaryComboComponent } from './_base/dictionary-combo.component';
import { TableComboComponent } from './_base/table-combo.component';
import { UtilsModule } from '@shared/utils/utils.module';
import { ICD9ComboV2Component } from './combo-filter/icd9/icd9-combo-v2.component';
import { ICD10ComboV2Component } from './combo-filter/icd10/icd10-combo-v2.component';
import { DanhMucTuongDuongComponent } from './combo-filter/danh-muc-tuong-duong/danh-muc-tuong-duong.component';
import { ICD10ComboMultiComponent } from './combo-filter/icd10/icd10-combo-multi.component';
import { ValidateICD10Component } from './combo-filter/icd10/icd10-validate.component';
import { GiayToTuyThanComponent } from './control-comp/giay-to-tuy-than/giay-to-tuy-than.component';
import { TextMaskModule } from 'angular2-text-mask';
import { UserComboComponent } from './combo-filter/user/user-combo.component';
import { HaiDepartmentTreeControlComponent } from './hai-amr/hai-department-tree/hai-department-tree.component';
import { HaiDepartmentMultiselectComponent } from './hai-amr/hai-department-multiselect/hai-department-multiselect.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CheckDepartmentGroupPipe } from './pipe/check-department-group.pipe';
import { ComboMultiselectComponent } from './_base/combo-multiselect/combo-multiselect.component';
import { CheckComboGroupPipe } from './pipe/check-combo-group.pipe';
import { ComboSelectComponent } from './_base/combo-select/combo-select.component';
const componentsEx = [
    //Empty
    EmptyComponent,

    //Directive
    CustomSourceDirective,

    //ComboBase
    EnumComboComponent,
    DictionaryComboComponent,
    TableComboComponent,
    ComboMultiselectComponent,
    ComboSelectComponent,

    //KieuDuLieu
    DropListDownComboComponent,
    RadioListComponent,
    CheckboxListComponent,

    //ComboBox
    DonViHanhChinhComboComponent,

    //Hệ thống
    RoleComboComponent,
    CoSoComboComponent,

    //DanhMuc ICD
    ICD9ComboComponent,
    ICD9ComboV2Component,
    ICD10ComboV2Component,
    ICD10ComboMultiComponent,
    ValidateICD10Component,

    //Danh mục tương đương
    DanhMucTuongDuongComponent,

    //Giấy tờ tùy thân
    GiayToTuyThanComponent,

    //ControlComp
    StatusActiveControlComponent,
    TreeViewSelectTypeComponent,
    DynamicControlComponent,

    //HAI-AMR
    HaiDepartmentTreeControlComponent,
    HaiDepartmentMultiselectComponent,
    //User
    UserComboComponent
];

const pipe = [
    CheckComboGroupPipe,
    CheckDepartmentGroupPipe
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        ...SHARED_ZORRO_MODULES,
        CustomizeCompModule,
        TextMaskModule,
        NgSelectModule,
    ],
    declarations: [...componentsEx, ...pipe],
    exports: [...componentsEx, ...pipe],
    providers: [
        //Lib
        DataCompService,
        ReadFileExcelService,
    ]
})

export class DataCompModule {
}
