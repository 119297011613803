
import { Compiler, Injectable, Injector, NgModuleFactory, NgModuleRef } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class LoadModuleService {

    constructor(
        private injector: Injector,
        private compiler: Compiler,
    ) {
    }

    async loadModuleDanhMuc(): Promise<NgModuleRef<any>> {
        const { DanhMucModule } = await import("@app/pages/danh-muc/danh-muc.module");
        const moduleFactory =
            DanhMucModule instanceof NgModuleFactory
                ? DanhMucModule
                : (await this.compiler.compileModuleAsync(DanhMucModule));

        return moduleFactory.create(this.injector);
    }

    async loadModuleHeThong(): Promise<NgModuleRef<any>> {
        const { HeThongModule } = await import("@app/pages/he-thong/he-thong.module");
        const moduleFactory =
            HeThongModule instanceof NgModuleFactory
                ? HeThongModule
                : (await this.compiler.compileModuleAsync(HeThongModule));

        return moduleFactory.create(this.injector);
    }

    async loadModuleQuanLyCoSo(): Promise<NgModuleRef<any>> {
        const { QuanLyCoSoModule } = await import("@app/pages/quan-ly-co-so/quan-ly-co-so.module");
        const moduleFactory =
            QuanLyCoSoModule instanceof NgModuleFactory
                ? QuanLyCoSoModule
                : (await this.compiler.compileModuleAsync(QuanLyCoSoModule));

        return moduleFactory.create(this.injector);
    }

    async loadModuleDanhGiaPXN(): Promise<NgModuleRef<any>> {
        const { DanhGiaPXNModule } = await import("@app/pages/danh-gia-pxn/danh-gia-pxn.module");
        const moduleFactory =
            DanhGiaPXNModule instanceof NgModuleFactory
                ? DanhGiaPXNModule
                : (await this.compiler.compileModuleAsync(DanhGiaPXNModule));

        return moduleFactory.create(this.injector);
    }
}
