<nz-tree-select
    style="width: 100%"
    nzPlaceHolder="-{{'_Chon' | localize}}-"
    nzShowSearch
    nzCheckable
    [(ngModel)]="departmentIds"
    [nzNodes]="departmentTypes"
    [nzMaxTagCount]="2"  
    (ngModelChange)="onChange($event)"
    [nzMaxTagPlaceholder]="tagPlaceHolderDepartmentTypes"
    [nzDisplayWith]="displaySelectedTitle"
    [nzDropdownStyle]="{ 'max-height': '200px', 'overflow-y': 'auto' }"
    [nzDefaultExpandAll]="false">
</nz-tree-select>

<ng-template #tagPlaceHolderDepartmentTypes let-selectedList>
    +{{ selectedList.length }} {{'_MucDaChon' | localize}}
</ng-template>
