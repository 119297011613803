import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    ComboBoxEnumCode,
    PhongXetNghiem_KyThuatDto,
    PhongXetNghiem_NhanSuDto,
    PhongXetNghiem_ThietBiDto,
    PhongXetNghiemDto,
    PhongXetNghiemServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { finalize } from '@node_modules/rxjs/operators';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { ChonKyThuatModalComponent } from '@app/pages/danh-gia-pxn/common/chon-ky-thuat-modal.component';
import { TYPE_VALIDATE } from '@shared/AppEnums';

@Component({
    selector: 'phong-xet-nghiem-create-edit',
    templateUrl: './phong-xet-nghiem-create-edit.component.html',
})
export class PhongXetNghiemCreateEditComponent extends AppComponentBase implements OnInit {
    @Input() dataItem: PhongXetNghiemDto = new PhongXetNghiemDto();
    @Output() close = new EventEmitter<any>();

    isView = false;
    saving = false;
    formId = 'formValidatePhongXetNghiem';
    ComboBoxEnumCode = ComboBoxEnumCode;
    TYPE_VALIDATE = TYPE_VALIDATE;

    constructor(
        injector: Injector,
        private _phongXetNghiemService: PhongXetNghiemServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else {
            this.clearForm();
            this.dataItem.listKyThuat = [];
            this.dataItem.listNhanSu = [];
            this.dataItem.listThietBi = [];
            if (this.appSession.user.coSoId > 0) {
                this.dataItem.coSoId = this.appSession.user.coSoId;
            }
            this.buildNhanSuMau();
        }
    }

    getInfoById(id: number): void {
        abp.ui.setBusy();
        this._phongXetNghiemService
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                let data = response;
                this.dataItem = data;
                //Add các giá trị mặc định
                if (!this.dataItem.listNhanSu || this.dataItem.listNhanSu.length === 0) {
                    this.buildNhanSuMau();
                }
            });
    }

    buildNhanSuMau() {
        this.dataItem.listNhanSu = [];
        this.dataItem.listNhanSu.push(this.addNhanSuMau('Bác sĩ', null, 1));
        this.dataItem.listNhanSu.push(this.addNhanSuMau('Dược sĩ', null, 2));
        this.dataItem.listNhanSu.push(this.addNhanSuMau('Cử nhân', null, 3));
        this.dataItem.listNhanSu.push(this.addNhanSuMau('- Cử nhân xét nghiệm', 3, 4));
        this.dataItem.listNhanSu.push(this.addNhanSuMau('- Cử nhân công nghệ sinh học', 3, 5));
        this.dataItem.listNhanSu.push(this.addNhanSuMau('- Cử nhân sinh học', 3, 6));
        this.dataItem.listNhanSu.push(this.addNhanSuMau('- Kỹ sư hóa học', 3, 7));
        this.dataItem.listNhanSu.push(this.addNhanSuMau('Kỹ thuật viên xét nghiệm', null, 8));
        this.dataItem.listNhanSu.push(this.addNhanSuMau('Điều dưỡng', null, 9));
        this.dataItem.listNhanSu.push(this.addNhanSuMau('Khác', null, 99));
        this.dataItem.listNhanSu.push(this.addNhanSuMau('Tổng', null, 100));
    }

    addNhanSuMau(trinhDo, parentId, soThuTu) {
        let nhanSu = new PhongXetNghiem_NhanSuDto();
        nhanSu.id = 0;
        nhanSu.trinhDo = trinhDo;
        nhanSu.soLuong = 0;
        nhanSu.parentId = parentId;
        nhanSu.soThuTu = soThuTu;
        return nhanSu;
    }

    save(): void {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error('Bạn cần nhập đủ dữ liệu các trường có dấu * đỏ !!');
            return;
        }
        abp.ui.setBusy();
        this._phongXetNghiemService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    // this.close.emit(true);
                } else {
                    this.notify.error(this.l('HasError'));
                }
            });
    }

    clearForm() {
        this.dataItem = Object.assign(new PhongXetNghiemDto(), {
            isDungMaHeThong: true,
        });
    }

    //#region Kỹ thuật
    addKyThuat() {
        this.modalHelper
            .create(
                ChonKyThuatModalComponent,
                {},
                {
                    size: 'xl',
                    includeTabs: false,
                    modalOptions: {
                        nzTitle: 'Chọn danh mục kỹ thuật xét nghiệm',
                    },
                },
            )
            .subscribe((result) => {
                if (result) {
                    result.forEach((item) => {
                        let check = this.dataItem.listKyThuat?.find(x => x.maKyThuat === item.code);
                        if (!check) {
                            let kythuat = new PhongXetNghiem_KyThuatDto();
                            kythuat.id = 0;
                            kythuat.maKyThuat = item.code;
                            kythuat.tenKyThuat = item.display;
                            kythuat.thucHienNoiKiem = false;
                            kythuat.thucHienNgoaiKiemLienPhong = false;
                            kythuat.soXetNghiemTrungBinh = 0;
                            this.dataItem.listKyThuat.push(kythuat);
                        }
                    });
                }
            });
    }

    deleteKyThuat(index: number) {
        this.dataItem.listKyThuat.splice(index, 1);
    }

    //#endregion

    //#region Nhân sự
    addNhanSu() {
        this.dataItem.listNhanSu.push(new PhongXetNghiem_NhanSuDto());
    }

    deleteNhanSu(index: number) {
        this.dataItem.listNhanSu.splice(index, 1);
    }

    //#endregion

    //#region Nhân sự
    addThietBi() {
        this.dataItem.listThietBi.push(new PhongXetNghiem_ThietBiDto());
    }

    deleteThietBi(index: number) {
        this.dataItem.listThietBi.splice(index, 1);
    }

    //#endregion
    changeSoLuong(nhanSu: PhongXetNghiem_NhanSuDto, event: number) {
        nhanSu.soLuong = event;
        if (nhanSu.parentId) {
            let parent = this.dataItem.listNhanSu.findIndex(x => x.soThuTu === nhanSu.parentId);
            let tongChild = this.dataItem.listNhanSu.filter(x => x.parentId === nhanSu.parentId)
                .reduce((sum, item) => sum + item.soLuong, 0);
            this.dataItem.listNhanSu[parent].soLuong = tongChild;
            // this.dataItem.listNhanSu = _.cloneDeep(this.dataItem.listNhanSu);
        }
        let tong = this.dataItem.listNhanSu.findIndex(x => x.soThuTu === 100);
        let tongChild = this.dataItem.listNhanSu.filter(x => x.soThuTu !== 100 && !x.parentId)
            .reduce((sum, item) => sum + item.soLuong, 0);
        this.dataItem.listNhanSu[tong].soLuong = tongChild;
    }
}
