import {Pipe, PipeTransform} from '@angular/core';
import { DepartmentItemDto } from '@shared/service-proxies/service-proxies';

@Pipe({
  name: 'checkDepartmentGroup'
})
export class CheckDepartmentGroupPipe implements PipeTransform {

  transform(departmentIds: any[], departments: DepartmentItemDto[], organisationName: string): boolean {
    let ret = false;
    if (departmentIds && departmentIds.length > 0) {
      departments.forEach((it) => {
        if (it.organisationName === organisationName) {
          if (departmentIds.indexOf(it.id) > -1) {
            ret = true;
            return;
          }
        }
      })
    }

    return ret;
  }

}
