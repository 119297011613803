import { Component, Injector, OnInit } from '@angular/core';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { finalize } from 'rxjs/operators';
import { CLPXNDanhMucDto, DanhGiaCLPXNServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';

@Component({
    templateUrl: './chon-ky-thuat-modal.component.html',
})
export class ChonKyThuatModalComponent extends ModalComponentBase implements OnInit {
    saving = false;
    listKyThuat: CLPXNDanhMucDto[];
    listAllKyThuat: CLPXNDanhMucDto[];
    size = 'large';
    totalCount = 0;
    pageIndex = 1;
    pageSize = 10;
    checked = false;
    loading = false;
    indeterminate = false;
    setOfCheckedId = new Set<number>();
    listOfCurrentPageData: CLPXNDanhMucDto[] = [];
    textSearch: '';

    constructor(
        injector: Injector,
        private _dataService: DanhGiaCLPXNServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.getDanhMucKyThuat();
    }

    getDanhMucKyThuat() {
        abp.ui.setBusy();
        this._dataService
            .danhMucKyThuat()
            .pipe(finalize(() => {
            }))
            .subscribe((result) => {
                this.listKyThuat = result;
                this.listAllKyThuat = result;
                abp.ui.clearBusy();
            });
    }

    save(): void {
        let dataCheck = this.listKyThuat.filter(x => this.setOfCheckedId.has(x.id));
        this.nzModalRef.close(dataCheck);
    }

    //#region Table
    pageSizeChange(event: number) {
        this.pageSize = event;
    }

    pageIndexChange(event: number) {
        this.pageIndex = event;
    }

    updateCheckedSet(id: number, checked: boolean): void {
        if (checked) {
            this.setOfCheckedId.add(id);
        } else {
            this.setOfCheckedId.delete(id);
        }
    }

    refreshCheckedStatus(): void {
        const listOfEnabledData = this.listOfCurrentPageData;
        this.checked = listOfEnabledData.every(({ id }) => this.setOfCheckedId.has(id));
        this.indeterminate = listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
    }

    onItemChecked(id: number, checked: boolean): void {
        this.updateCheckedSet(id, checked);
        this.refreshCheckedStatus();
    }

    onCurrentPageDataChange(listOfCurrentPageData: CLPXNDanhMucDto[]): void {
        this.listOfCurrentPageData = listOfCurrentPageData;
        this.refreshCheckedStatus();
    }

    onAllChecked(checked: boolean): void {
        this.listOfCurrentPageData
            .forEach(({ id }) => this.updateCheckedSet(id, checked));
        this.refreshCheckedStatus();
    }

    //#endregion
    searchData() {
        let dataSearch = this.listAllKyThuat.filter(x => x.code.toLowerCase().includes(this.textSearch.toLowerCase()) || x.display.toLowerCase().includes(this.textSearch.toLowerCase()));
        this.listKyThuat = _.cloneDeep(dataSearch);
        this.pageIndex = 1;
    }
}
