<div class='margin-bottom-10'>
    <input style='width: 100%' [(ngModel)]='textSearch' (keydown.enter)='searchData()' nz-input
           placeholder='Tên kỹ thuật' />
</div>
<nz-table #kyThuat nzBordered
          [nzData]='listKyThuat'
          [nzFrontPagination]='true'
          [nzTotal]='totalCount'
          [nzShowSizeChanger]='true'
          [(nzPageIndex)]='pageIndex'
          (nzPageSizeChange)='pageSizeChange($event)'
          (nzPageIndexChange)='pageIndexChange($event)'
          [nzPageSize]='pageSize'
          (nzCurrentPageDataChange)='onCurrentPageDataChange($event)'>
    <thead>
    <tr>
        <th nzAlign='center' nzWidth='60px'>#</th>
        <th nzAlign='center' nzWidth='60px'>
            <label nz-checkbox [nzChecked]='checked'
                   [nzIndeterminate]='indeterminate'
                   nzLabel='Select all'
                   (nzCheckedChange)='onAllChecked($event)'></label>
        </th>
        <th nzWidth='120px'>Mã kỹ thuật</th>
        <th nzWidth='600px'>Tên kỹ thuật</th>
    </tr>
    </thead>
    <tbody>
    <ng-container>
        <tr *ngFor='let item of kyThuat.data; index as i'>
            <td nzAlign='center'>
                {{ (pageIndex - 1) * pageSize + i + 1 }}
            </td>
            <td nzAlign='center'>
                <label nz-checkbox [nzChecked]='setOfCheckedId.has(item.id)'
                       (nzCheckedChange)='onItemChecked(item.id, $event)'></label>
            </td>
            <td>{{ item.code }}</td>
            <td>
                {{ item.display }}
            </td>
        </tr>
    </ng-container>
    </tbody>
</nz-table>
<div *nzModalFooter>
    <button type='button' nz-button nzType='default' (click)='close()'>Đóng</button>
    <button [nzLoading]='saving' nz-button nzType='primary' (click)='save()'><i nz-icon nzType='save'></i>Chọn</button>
</div>

