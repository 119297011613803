import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImgTempModalComponent } from '@app/shared/customize-comp/img-temp-modal/img-temp-modal.component';
import { ImgTempSelectComponent } from '@app/shared/customize-comp/img-temp-select/img-temp-select.component';
import { DirViewPdfComponent } from '@app/shared/customize-comp/open-view-pdf/dir-view-pdf.component';
import { OraNumberRollbackComponent } from '@app/shared/customize-comp/ora-number-rollback/ora-number-rollback.component';
import { OraRollbackComponent } from '@app/shared/customize-comp/ora-rollback/ora-rollback.component';
import { OraNumberV2 } from '@app/shared/customize-comp/pipe/ora-number-v2.pipe';
import { SafePipe } from '@app/shared/customize-comp/pipe/SafePipe';
import { XoaTextHuyenPipe } from '@app/shared/customize-comp/pipe/xoa-text-huyen';
import { XoaTextXaPipe } from '@app/shared/customize-comp/pipe/xoa-text-xa';
import { AngularEditorModule } from '@node_modules/@kolkov/angular-editor';
import { CountoModule } from '@node_modules/angular2-counto';
import { NzOutletModule } from '@node_modules/ng-zorro-antd/core/outlet';
import { ColorPickerModule } from '@node_modules/ngx-color-picker';
import { ImageCropperModule } from '@node_modules/ngx-image-cropper';
import { SHARED_DELON_MODULES } from '@shared/shared-delon.module';
import { SHARED_ZORRO_MODULES } from '@shared/shared-zorro.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { TextMaskModule } from 'angular2-text-mask';
import { CircleDataComponent } from './circle-data/circle-data.component';
import { ValidationCustomComponent } from './custom-validate/validation-custom.component';
import { EditPageLayoutComponent } from './edit-page-layout/edit-page-layout.component';
import { FileDinhKemComponent } from './file-dinh-kem/file-dinh-kem.component';
import { OrdGeneralModule } from './general/ord-general.module';
import { OpenViewPdfModalComponent } from './open-view-pdf/open-view-pdf-modal.component';
import { OraCKEditorComponent } from './ora-ckeditor/ora-ckeditor.component';
import { OraColorPickerComponent } from './ora-color-picker/ora-color-picker.component';
import { OraDatePickerComponent } from './ora-date-picker/ora-date-picker.component';
import { OraEditorComponent } from './ora-editor/ora-editor.component';
import { ViewPdfModalComponent } from './ora-file-upload/modal/view-pdf.component';
import { OraFileUploadControlComponent } from './ora-file-upload/ora-file-upload-control.component';
import { OraFilterSpinComponent } from './ora-filter-spin/ora-filter-spin.component';
import { OraLayoutFilterComponent } from './ora-layout-filter/ora-layout-filter.component';
import { OraListSwitchComponent } from './ora-list-switch/ora-list-switch.component';
import { OraLogComponent } from './ora-log/ora-log.component';
import { OraPageHeaderComponent } from './ora-page-header/ora-page-header.component';
import { OraRangeDatePickerComponent } from './ora-range-date-picker/ora-range-date-picker.component';
import { OraSelectComponent } from './ora-select/ora-select.component';
import { OraSliderComponent } from './ora-slider/ora-slider.component';
import { OraStepComponent } from './ora-steps/ora-step/ora-step.component';
import { OraStepsComponent } from './ora-steps/ora-steps.component';
import { OraSwitchComponent } from './ora-switch/ora-switch.component';
import { OraTableModule } from './ora-table/ora-table.module';
import { OraTxtSearchComponent } from './ora-txt-search/ora-txt-search.component';
import { OrdOverlayContentComponent } from './ord-overlay-tringer/ord-overlay-content/ord-overlay-content.component';
import { OrdPagesCustomModule } from './pages-custom/ord-pages-custom.module';
import { OrdPwdInputComponent } from './pass-word-custom/ord-pwd-input.component';
import { AbsPipe } from './pipe/abs.pipe';
import { OraNumberStrPipe } from './pipe/ora-numberStrPipe';
import { ReplaceSomeTextPipe } from './pipe/replace-some-text.pipe';
import { StringToObjPipe } from './pipe/string-to-obj.pipe';
import { UppercaseFirstLetterPipe } from './pipe/uppercase-first-letter.pipe';
import { UppercaseFirstWordPipe } from './pipe/uppercase-first-word.pipe';
import { XoaTextTinhPipe } from './pipe/xoa-text-tinh';
import { FilterPipe } from './pipe/FilterPipe';
import { ProgressBarType2Component } from './progress-bar-type2/progress-bar-type2.component';
import { ProgressBarWithPointComponent } from './progress-bar-with-point/progress-bar-with-point.component';
import { SearchListPageLayoutComponent } from './search-list-page-layout/search-list-page-layout.component';
import { CustomFileUploadControlComponent } from './upload-file/file-upload-control.component';
import { CustomFileUploadMultiControlComponent } from './upload-file/file-upload-multi-control.component';
import { UploadMultiFileComponent } from './upload-file/modal/upload-multi-file/upload-multi-file.component';
import { ViewPdfKetQuaComponent } from './upload-file/modal/view-pdf/view-pdf.component';
import { ViewPdfKetQuaV2Component } from './upload-file/modal/view-pdf-v2/view-pdf/view-pdf.component';
import { CommonBtnComponent } from './ora-button/common-btn.component';
import { AddNewBtnComponent } from './ora-button/add-new-btn.component';
import { CancelBtnComponent } from './ora-button/cancel-btn.component';
import { SaveBtnComponent } from './ora-button/save-btn.component';
import { OraDateTimePickerComponent } from './ora-date-picker/ora-date-time-picker.component';
import { OraYearPickerComponent } from './ora-date-picker/ora-year-picker.component';
import { OraFieldSetComponent } from './ora-field-set/ora-field-set.component';
import { OraChangePositionComponent } from './ora-change-position/ora-change-position.component';
import { OraDayYearPickerComponent } from './ora-date-picker/ora-day-year-picker.component';
import { OraDateMonthYearComponent } from './ora-date-picker/ora-date-month-year.component';
import { CustomFileMultiUploadV2ControlComponent, ExtractAfterSlashPipe } from './upload-file/file-upload-multi-control-v2.component';
import { OraDatePickerV2Component } from './ora-date-picker/ora-date-picker-v2.component ';
import { YearDirective } from '@app/shared/customize-comp/directives/year.directive';

const com = [
    SafePipe,
    OpenViewPdfModalComponent,
    DirViewPdfComponent,
    OraEditorComponent,
    XoaTextXaPipe,
    XoaTextHuyenPipe,
    XoaTextTinhPipe,
    OraNumberStrPipe,
    OraNumberV2,
    OraDatePickerComponent,
    ImgTempModalComponent,
    ImgTempSelectComponent,
    CircleDataComponent,
    ProgressBarWithPointComponent,
    OraNumberRollbackComponent,
    OraRollbackComponent,
    ProgressBarType2Component,
    UppercaseFirstWordPipe,
    UppercaseFirstLetterPipe,
    StringToObjPipe,
    FilterPipe,
    AbsPipe,
    OraSliderComponent,
    OraColorPickerComponent,
    ReplaceSomeTextPipe,
    OraStepsComponent,
    OraStepComponent,
    OraSwitchComponent, OraListSwitchComponent,
    OraLayoutFilterComponent,
    OraPageHeaderComponent,
    OraFilterSpinComponent,
    ValidationCustomComponent,
    OrdPwdInputComponent,
    FileDinhKemComponent,
    CustomFileUploadControlComponent,
    CustomFileMultiUploadV2ControlComponent,
    CustomFileUploadMultiControlComponent,
    UploadMultiFileComponent,
    ViewPdfKetQuaComponent,
    ViewPdfKetQuaV2Component,
    OraCKEditorComponent,
    SearchListPageLayoutComponent,
    OraRangeDatePickerComponent,
    OraSelectComponent,
    OraFileUploadControlComponent,
    ViewPdfModalComponent,
    OrdOverlayContentComponent,
    OraTxtSearchComponent,
    EditPageLayoutComponent,
    OraLogComponent,
    CommonBtnComponent,
    AddNewBtnComponent,
    CancelBtnComponent,
    SaveBtnComponent,
    OraDateTimePickerComponent,
    OraYearPickerComponent,
    OraFieldSetComponent,
    OraChangePositionComponent,
    OraDayYearPickerComponent,
    OraDateMonthYearComponent,
    OraDatePickerV2Component,
    YearDirective,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ...SHARED_ZORRO_MODULES,
        ...SHARED_DELON_MODULES,
        AngularEditorModule,
        TextMaskModule,
        ImageCropperModule,
        UtilsModule,
        CountoModule,
        ColorPickerModule,
        NzOutletModule,
    ],
    declarations: [...com, ExtractAfterSlashPipe ],
    exports: [...com,OraTableModule,OrdGeneralModule,OrdPagesCustomModule ],
    providers: [],
})
export class CustomizeCompModule {

}

