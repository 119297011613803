<ng-select #vcSelect
           [items]="options"
           [multiple]="true"
           [closeOnSelect]="false"
           [groupBy]="isGroup?'organisationName':''"
           [selectableGroup]="isGroup"
           [selectableGroupAsModel]="false"
           bindLabel="name"
           bindValue="id"
           placeholder="-{{'_Chon' | localize}}-"
           [formControl]="control"
           (change)="onChangeSelect()"
           [searchable]="true"
           [searchFn]="testSearch" (search)="onSearch($event)">

    <!--<ng-template ng-header-tmp let-search="searchTerm">

    </ng-template>-->

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items | slice:0:multiMaxShowItem">
            <span *ngIf="scope === PHAM_VI_DU_LIEU_HAI.NOI_BO_DON_VI">* </span>{{item.name}}
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
        <div class="ng-value" *ngIf="items.length > multiMaxShowItem">
            <span class="ng-value-label">+{{items.length - multiMaxShowItem}}...</span>
        </div>
    </ng-template>

    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{index}}" type="checkbox"
               [ngModel]="control.value|checkDepartmentGroup:options:item.organisationName" />
        <span *ngIf="scope === PHAM_VI_DU_LIEU_HAI.NOI_BO_DON_VI">* </span> {{item.organisationName | uppercase}}
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
        <span *ngIf="scope === PHAM_VI_DU_LIEU_HAI.NOI_BO_DON_VI">* </span> {{item.name}}
    </ng-template>

    <ng-template ng-footer-tmp let-search="searchTerm">
        <div>
            <button class="btn btn-outline-success btn-sm" (click)="selectAll()" style="margin-right:5px;">
                {{'_ChonToanBo' | localize}}
            </button>
            <button class="btn btn-outline-secondary btn-sm" (click)="vcSelect.clearModel()">
                {{'_BoChonToanBo' | localize}}
            </button>
        </div>
    </ng-template>
</ng-select>
