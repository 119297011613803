import { Pipe, PipeTransform } from '@angular/core';
import { ComboItemDto } from '@shared/service-proxies/service-proxies';

@Pipe({
    name: 'checkComboGroup'
})
export class CheckComboGroupPipe implements PipeTransform {

    transform(arrIds: any[], options: ComboItemDto[], groupName: string): boolean {
        let ret = false;
        if (arrIds && arrIds.length > 0) {
            options.forEach((it) => {
                if (it.groupName === groupName) {
                    if (arrIds.indexOf(it.id) > -1) {
                        ret = true;
                        return;
                    }
                }
            })
        }
        return ret;
    }
}
