<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="[16,6]">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <label class="font-weight-normal">{{'_Ma' | localize}}<span class="text-red">*</span></label>
            <nz-form-control>
                <input nz-input [(ngModel)]="dataItem.code" [disabled]="true">
            </nz-form-control>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">{{'_LoaiPhauThuat' | localize}}<span class="text-red">*</span></label>
            <table-combo [TableEnum]="ComboBoxTableCode.GetAllSSIClassTypeICD10" [(ngModel)]="dataItem.code">
            </table-combo>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <label class="font-weight-normal">{{'_MaBaoHiem' | localize}}</label>
            <input nz-input [(ngModel)]="dataItem.insurance_code" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <label class="font-weight-normal">{{'_TenTiengAnh' | localize}}<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.name" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.name"></validation-custom>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
            <label class="font-weight-normal">{{'_TenTiengViet' | localize}}<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.name_vi" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.name_vi"></validation-custom>
        </div>
    </div>
    <div *nzModalFooter>
        <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>{{'_TiepTucThem' | localize}}</b></label>
        <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>{{'_Luu' | localize}}</button>
        <button type="button" nz-button nzType="default" (click)="close()">{{'Close' | localize}}</button>
    </div>
</div>
